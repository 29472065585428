export type Part = {
  id: PartId;
  actions: Action[];
};

export enum PartId {
  START = "START",
  HTML_WHY = "HTML_WHY",
  HTML_START_1 = "HTML_START_1",
  HTML_START_2 = "HTML_START_2",
}

export enum ActionType {
  NONE = "NONE",
  PRESENT = "PRESENT",
  ASK_NEXT = "ASK_NEXT",
  TERMS_ADD = "TERMS_ADD",
}

export type Action = ActionPresent | ActionAskNext | ActionTermsAdd;

export type ActionPresent = {
  type: ActionType.PRESENT;
  content: JSX.Element;
};

export type ActionAskNext = {
  type: ActionType.ASK_NEXT;
  nextPartId: PartId;
  content: string;
};

export type ActionTermsAdd = {
  type: ActionType.TERMS_ADD;
  terms: TermId[];
};

export enum TermId {
  HTML = "HTML",
  CSS = "CSS",
}

export interface Term {
  id: TermId;
  content: string;
}
