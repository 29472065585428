import { start } from "./start";
import {
  ActionAskNext,
  ActionPresent,
  ActionTermsAdd,
  ActionType,
  Part,
  PartId,
} from "./parts.type";
import { htmlWhy } from "./html-why";
import { htmlStart } from "./html-start";

export const parts: Part[] = [start, htmlWhy, ...htmlStart];

export function getPart(id: PartId) {
  return parts.find((p) => p.id === id);
}

export function getAction(partId: PartId, actionType: ActionType) {
  return getPart(partId)?.actions.find((a) => a.type === actionType);
}

export function getActionPresent(partId: PartId) {
  return getAction(partId, ActionType.PRESENT) as ActionPresent;
}

export function getActionAskNext(partId: PartId) {
  return getAction(partId, ActionType.ASK_NEXT) as ActionAskNext;
}

export function getActionTermsAdd(partId: PartId) {
  return getAction(partId, ActionType.TERMS_ADD) as ActionTermsAdd;
}
