import React from "react";
import s from "./App.module.css";
import { useImmerReducer } from "use-immer";
import { MainState, mainStateReducer } from "./main-state";
import { ActionType, PartId } from "./parts/parts.type";
import {
  getActionAskNext,
  getActionPresent,
  getActionTermsAdd,
} from "./parts/parts";
import tim from "./assets/tim.png";
import timNewTerm from "./assets/tim-new-term.png";

function App() {
  const [state, dispatch] = useImmerReducer(mainStateReducer, {
    partId: PartId.START,
    prevActionType: ActionType.NONE,
  } as MainState);

  let tutorContent;
  let actionsContent;
  if (state.prevActionType === ActionType.TERMS_ADD) {
    const aTermsAdd = getActionTermsAdd(state.partId);
    tutorContent = (
      <>
        <h1>Nowe terminy</h1>
        <ul>
          {aTermsAdd.terms.map((tId) => (
            <li key={tId}>{tId}</li>
          ))}
        </ul>
      </>
    );
    actionsContent = (
      <button onClick={dispatch.bind(null, ActionType.ASK_NEXT)}>OK</button>
    );
  } else {
    tutorContent = getActionPresent(state.partId).content;
    actionsContent = (
      <button onClick={dispatch.bind(null, ActionType.ASK_NEXT)}>
        {getActionAskNext(state.partId).content}
      </button>
    );
  }

  return (
    <>
      <div className={s.main}>
        <div className={s.tutor}>
          <div className={s.tutorContent}>{tutorContent}</div>
          <div className={s.tutorAvatar}>
            <img src={getTutorAvatarImg(state)}></img>
          </div>
        </div>
        <div className={s.actions}>{actionsContent}</div>
      </div>
    </>
  );
}

function getTutorAvatarImg(state: MainState) {
  switch (state.prevActionType) {
    case ActionType.TERMS_ADD:
      return timNewTerm;
    default:
      return tim;
  }
}

export default App;
