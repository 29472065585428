import { ActionType, Part, PartId, TermId } from "./parts.type";

export const start: Part = {
  id: PartId.START,
  actions: [
    {
      type: ActionType.PRESENT,
      content: (
        <>
          <h1>Witaj w kursie HTML!</h1>
          <p>
            HTML <i>(Hyper Text Transfer Protocol)</i> to podstawowy język
            wykorzystywany do tworzenia stron internetowych. Bez niego nie
            byłoby możliwe wyświetlanie tekstu, obrazów i innych elementów na
            stronach internetowych.
          </p>

          <p>
            W tym kursie nauczysz się, jak korzystać z różnych elementów HTML,
            aby stworzyć proste strony internetowe.
          </p>

          <p>
            Nie potrzebujesz wcześniejszego doświadczenia w programowaniu, aby
            rozpocząć ten kurs.
          </p>
          <p>
            <b>Do dzieła!</b>
          </p>
        </>
      ),
    },
    {
      type: ActionType.ASK_NEXT,
      nextPartId: PartId.HTML_WHY,
      content: "Ok, zaczynajmy",
    },
    {
      type: ActionType.TERMS_ADD,
      terms: [TermId.HTML],
    },
  ],
};
