import styles from "./CodeEditor.module.css";
import { basicSetup, EditorView } from "codemirror";
import { useEffect, useRef } from "react";
import { html } from "@codemirror/lang-html";
import { EditorState } from "@codemirror/state";

export function CodeEditor({ doc }: { doc: string }) {
  const refEditor = useRef(document.createElement("div"));
  const refEditorPreview = useRef(document.createElement("div"));

  useEffect(() => {
    const startState = EditorState.create({
      doc,
      extensions: [
        basicSetup,
        html(),
        EditorView.updateListener.of((update) => {
          refEditorPreview.current.innerHTML = update.state.doc
            .toJSON()
            .join("");
        }),
      ],
    });
    const view = new EditorView({
      state: startState,
      parent: refEditor.current,
    });
    return () => {
      view.destroy();
    };
  });

  return (
    <div className={styles.codeEditorWrap}>
      <div ref={refEditor} className={styles.codeEditor}></div>
      <div ref={refEditorPreview} className={styles.codePreview}></div>
    </div>
  );
}
