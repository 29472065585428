import { ActionType, PartId } from "./parts/parts.type";
import { getActionAskNext, getActionTermsAdd } from "./parts/parts";

export interface MainState {
  partId: PartId;
  prevActionType: ActionType;
}

export function mainStateReducer(draft: MainState, actionType: ActionType) {
  switch (actionType) {
    case ActionType.ASK_NEXT: {
      const aTermsAdd = getActionTermsAdd(draft.partId);
      if (aTermsAdd && draft.prevActionType !== ActionType.TERMS_ADD) {
        draft.prevActionType = ActionType.TERMS_ADD;
      } else {
        draft.partId = getActionAskNext(draft.partId).nextPartId;
        draft.prevActionType = ActionType.NONE;
      }
      break;
    }
  }
}
