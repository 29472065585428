import { ActionType, Part, PartId } from "./parts.type";
import { CodeEditor } from "../components/CodeEditor";

const docStart = "To jeszcze nie HTML, ale od czegoś trzeba zacząć.";

export const htmlStart: Part[] = [
  {
    id: PartId.HTML_START_1,
    actions: [
      {
        type: ActionType.PRESENT,
        content: (
          <>
            <h1>Pisanie stron www...</h1>
            PE{" "}
            <p>
              Wyobraźmy sobie, że chcielibyśmy napisać książkę. Z czego by
              składała się jej treść? Oczywiście z tekstu, ale czy tylko z
              niego?
            </p>
            <p>
              Chcielibyśmy podzielić ją na rozdziały. Każdy z nich miałby tytuł
              pisany inną czcionką niż reszta tekstu. Dialogi przedstawione
              byłyby również na swój sposób - za pomocą innego formatowania oraz
              myślników.
            </p>
            <p>
              Innymi słowy - nasza książka skladałaby się z tekstu, ale pełniłby
              on różną rolę (tytuły, akapity, dialogi, cytaty...).
            </p>
            <p>
              Podobnie jest ze stronami www - każdy fragment tekstu który na
              nich widzimy ma inne przeznaczenie, które musi być określone przez
              autora.
            </p>
          </>
        ),
      },
      {
        type: ActionType.ASK_NEXT,
        content: "Ok, przejdźmy dalej",
        nextPartId: PartId.HTML_START_2,
      },
    ],
  },
  {
    id: PartId.HTML_START_2,
    actions: [
      {
        type: ActionType.PRESENT,
        content: (
          <>
            <h1>Edytor HTML.</h1>
            <p>
              Zanim nauczymy się określać wspomniane role, zapoznaj się edytorem
              HTML, z którego będziemy korzystać.
            </p>
            <p>
              Po lewej masz do dyspozycji edytor tekstu, który przyjmuje treść
              kodu HTML. Po prawej prezentowany jest efekt końcowy strony www.
              Jak na razie nie jest on imponujący, ale zawsze to jakiś start.
            </p>
            <p>
              Śmiało - spróbuj edytować poniższy tekst i zaobserwuj jak
              odzwierciedla się on po prawej stronie.
            </p>

            <CodeEditor doc={docStart}></CodeEditor>

            <p>Zauważ że ignorowane są nowe linie, czy wielokrotne spacje</p>

            <p>
              Potraktuj to jak pisanie książki jedynie za pomocą zdań. Możesz
              przekazać treść, jednak bez odpowiedniej formy brakować będzie
              Twojej intencji, kontekstu czy wyglądu.
            </p>
          </>
        ),
      },
      {
        type: ActionType.ASK_NEXT,
        content: "Ok, przejdźmy dalej",
        nextPartId: PartId.START,
      },
    ],
  },
];
