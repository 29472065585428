import { ActionType, Part, PartId } from "./parts.type";

export const htmlWhy: Part = {
  id: PartId.HTML_WHY,
  actions: [
    {
      type: ActionType.PRESENT,
      content: (
        <>
          <h1>Po co uczyć się języka HTML?</h1>
          <p>
            Osoba ucząca się pisania stron internetowych powinna poznać HTML z
            kilku powodów:
          </p>
          <ul>
            <li>
              HTML jest podstawowym budulcem stron internetowych - bez jego
              znajomości nie jest możliwe utworzenie nawet najprostszej strony
              internetowej.
            </li>
            <li>
              HTML został użyty do budowy każdej strony WWW jaką widziałeś.
            </li>
            <li>
              Poznanie HTML powinno być pierwszym krokiem przed nauką kolejnych
              języków używanych do tworzenia stron www jak CSS czy Javascript.
            </li>
          </ul>
        </>
      ),
    },
    {
      type: ActionType.ASK_NEXT,
      nextPartId: PartId.HTML_START_1,
      content: "W porządku, przejdźmy dalej",
    },
  ],
};
